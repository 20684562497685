:root {
	--primary: #154877;
	--secondary: #2D7698;
	--third: #ACB9AB;
	--forth: #BBB593;
	--fifth: #fcefb3;

}

.ar {
	direction: rtl !important;
}

h1,
h2,
h3,
h4,
h5,
h6 div,
a,
p,
span,
header,
section,
article,
::placeholder {
	font-family: Cairo !important;
}

/* body {
    background-color: cadetblue;
} */

html,
body,
#root {
	height: 100%;
}



.abs-container {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

/* Latest & Orgs (Start) */

.articles-orgs-section {
	background-color: #fafafa;
}



/* ============================================= */
/* ================== Pagination =============== */
/* ============================================= */

.pagination .page-link {
	color: var(--primary);
}

.pagination .active>.page-link,
.page-link.active {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
	color: #fff;
}

.pagination .page-link:focus,
.pagination .page-link:hover,
.pagination .page-link:active {
	box-shadow: unset;
	color: var(--secondary)
}

/* ******************************************************* */
/* ********************** Top Nav ************************ */
/* ******************************************************* */
.topNav {
	background-color: var(--primary);
	display: flex;
	padding: 0.9rem 1.6rem;
	align-items: center;
	gap: 1rem;
	flex-wrap: wrap;
	color: #fff;
}

.topNav .items {
	align-items: center;
	gap: 1.5rem
}

.topNav .item {
	cursor: pointer;
	color: #fff;
	text-decoration: none;

}

.topNav .item span {
	margin: 0 5px;
}

.topNav .toggler-btn button {
	padding: 5px 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: var(--primary);
	border: 2px solid #ffffff9e;
	transition: all .5s ease;

}

.topNav .toggler-btn button:focus,
.topNav .toggler-btn button:active {
	box-shadow: none;
	border: 2px solid #fff;
}



.topNav .navbar-toggler {
	display: none;
}

.topNav .offcanvas-body,
.topNav .offcanvas-header {
	background-color: var(--primary);
}

.topNav .offcanvas-body .nav-link {
	color: #fff;
}

@media only screen and (max-width: 900px) {
	.topNav {
		padding: 15px 0;
	}

	.topNav .navbar-toggler {
		display: block;
	}

	.topNav .second-nav {
		/* width: 90%; */
		display: none !important;
	}

	.topNav .container-fluid {
		flex-direction: row-reverse;
	}

	.hide-mobile {
		display: none;
	}
}







/* ------------- Media Queries ------------- */

@media screen and (max-width: 480px) {
	.container {
		padding: 0 1.1rem;
	}
}