h1,
h2,
h3,
h4,
h5,
h6 div,
a,
p,
span,
header,
section,
article,
::placeholder {
	font-family: Cairo !important;
}

/*----------------- TO BE MOVED LATER ---------------------*/
.reportTitle h4 {
	color: var(--primary);
	font-size: 2rem;
}

/*---------------------------------------------------------*/

.news {
	padding: 5rem 0;
}

.news .news-title h2 {
	margin: 0;
	font-size: 3rem;
	font-weight: 600;
	line-height: 3rem;
	display: flex;
	gap: .15rem;
	margin-bottom: .5rem;
	color: var(--primary);
}

.news hr {
	opacity: 0.15;
	margin-bottom: 2rem;
}

.newsList .card {
	transition: all ease 0.15s;
}

.news .card:hover,
.newsList .card:hover {
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.newsList .card .card-img-top {
	width: 100%;
	min-height: 220px;
	max-height: 250px;
	background-color: #ce9797;
	overflow: hidden;
}

.newsList .card-body {
	padding: 1rem 1.5rem;
}

.newsList .card-body .card-date {
	color: #e63158;
	font-size: .9rem;
	letter-spacing: 0.02rem;
	margin-bottom: .1rem;
}

.newsList .card-body .card-title {
	font-size: 1.3rem;
	font-weight: 600;
	line-height: 2.1rem;
	margin-bottom: .5rem;
	color: var(--primary);
}

.newsList .card-body .card-text {
	font-size: .95rem;

	overflow: hidden;
	color: #666;
	letter-spacing: 0.03rem;
	line-height: 1.75rem;
	margin-bottom: 1rem;
}

/* ***************** Pagination ************** */
.page-item:first-child .page-link {
	border-radius: unset !important;
}

.page-item:last-child .page-link {
	border-radius: unset !important;
}

/*------------------ Main Post ------------------*/

.news .card {
	transition: all ease 0.15s;
}

.news .card img {
	height: 500px;
	border-radius: 5px;
}

.card-img-overlay {
	top: auto;
	background: #ffffffe8;
	padding: 1.5rem 2rem 3rem;
}

.card-img-overlay .card-date {
	color: #e63158;
	font-size: 1.1rem;
	letter-spacing: 0.02rem;
	margin-bottom: 0;
}

.card-img-overlay .card-title {
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 2.1rem;
	margin-bottom: .5rem;
	color: var(--primary);
}

.card-img-overlay .card-text {
	font-size: 1rem;
	overflow: hidden;
	color: #555;
	letter-spacing: 0.025rem;
	line-height: 1.7rem;
}


/* .news .articlesImage {
	width: 170px;
}

.news .articlesImage img {
	width: 100%;
}

.newsList .card-text p {
	font-size: 15px;
} */

@media screen and (max-width: 500px) {
	.news {
		padding: 5rem 1rem;
	}

	.news .articlesImage {
		width: 160px;
	}
}