.about-us {
    margin: 5rem auto;
}

.about-us h2 {
    color: #154877;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3rem;
    display: flex;
    margin-bottom: 1.25rem;
    gap: .5rem;
    text-transform: capitalize;
}

.about-us h2 span {
    color: #e63158;
}

.about-us hr {
    opacity: .15;
    margin-bottom: 2rem;
}

.about-us p {
    text-align: justify;
    font-size: 1rem;
    letter-spacing: 0.03rem;
    line-height: 1.9rem;
    color: #5f6267;
    font-weight: 400;
}

.about-us .about-img {
    min-height: 400px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 20px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 12px;
}





@media screen and (max-width: 992px) {


    .about-us .col-img {
        order: 1;
        margin-bottom: 2rem;
    }

    .about-us .col-text {
        order: 2;
    }

    .about-us .col-text-2 {
        order: 3;
    }


}



@media screen and (max-width: 567px) {


    .about-us .col-img .about-img {
        min-height: unset;
        height: 280px;

    }

}